.header {
	$header: &;
	&__general {
		background-color: $dusty-orange;
		&_wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		&_side {
			display: flex;
			align-items: center;
			flex-direction: row;
		}
	}
	&__basket {
		&_circle { position: relative; }
		&_counter {
			position: absolute;
			top: -4px; right: -4px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 14px; height: 14px;
			border-radius: 7px;
			background-color: $greyish-brown;
			font-size: 9px;
			color: $white;
		}
	}
	&__action {
		display: flex;
		flex-direction: row;
		align-items: center;
		border: none;
		background-color: transparent;
		cursor: pointer;
		padding: 0;
		&_circle {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 34px; height: 34px;
			border-radius: 17px;
			background-color: $white;
		}
		&_name {
			display: block;
			margin-left: 10px;
			max-width: 82px;
			font-size: 12px;
			letter-spacing: .2px;
			color: $white;
			text-align: left;
		}
	}
	&__nav {
		background-color: $greyish-brown;
		&_wrapper {
			display: flex;
			justify-content: space-between;
		}
		&_block {
			display: flex;
			flex-direction: row;
		}
		&_list {
			display: flex;
			flex-direction: row;
			> li {
				position: relative;
				display: flex;
				align-items: center;
				position: relative;
				margin-left: 20px;
				&:not(:first-child) {
					padding-left: 20px;
					&::before {
						content: '';
						position: absolute;
						top: 0; bottom: 0; left: 0;
						margin: auto;
						width: 1px; height: 30px;
						background-color: rgba($white, .4);
					}
				}
				&:hover {
					> ul {
						display: block;
					}
				}
				> a {
					font-size: 14px;
					letter-spacing: 0.2px;
					text-transform: uppercase;
					color: $white;
				}
				> ul {
					position: absolute;
					display: none;
					top: 100%; left: 0;
					width: 230px; height: auto;
					padding: 15px;
					z-index: 1;
					background-color: #fff;
					> li {
						cursor: pointer;
						&:not(:last-child) {
							margin-bottom: 10px;
						}
						> a {
							cursor: pointer;
							transition: color 200ms;
							&:hover {
								color: $dusty-orange;
							}
						}
					}
				}
			}
		}
	}
	&__location {
		display: block;
		height: 100%;
		width: 270px;
		.slick {
			&-arrow {
				text-indent: -9999px;
				background-image: url('./../images/icon-location-arrow.svg');
				background-color: transparent;
				border: none;
				top: 28%;
			}
			&-prev {
				left: 0;
				transform: rotate(180deg);
			}
			&-next {
				right: 0;
				transform: rotate(360deg);
			}
		}
		&_block {
			text-align: center;
			height: 50px;
			padding-top: 14px;
		}
		&_tel {
			font: {
				weight: 700;
				size: 14px;
			}
			color: $white;
			letter-spacing: 0.2px;
		}
		&_city {
			margin-left: 10px;
			font-size: 14px;
			color: $white;
		}
	}
	&__catalog {
		$catalog_menu: &;
		&_button {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			background-color: $camo-green;
			border: none;
			cursor: pointer;
			&.active {
				&:hover {
					#{ $catalog_menu }_nav {
						display: block;
					}
				}
			}
			svg {
				fill: $white;
			}
			span {
				margin-left: 14.5px;
				font: {
					weight: 700;
					size: 14px;
				}
				color: $white;
				letter-spacing: 0.2px;
				text-transform: uppercase;
			}
		}
		&_nav {
			display: none;
			position: absolute; z-index: 10;
			top: 100%; left: 0;
			width: 100%; height: auto;
			background-color: $white;
		}
		&_list {
			li {
				a {
					display: block;
					padding: 17.5px 15px;
					@include Bodycopy-B4-dark;
					transition: color 200ms;
					&:hover {
						color: $dusty-orange;
					}
				}
				&:not(:last-child) {
					a {
						border-bottom: 1px solid $white-two;
					}
				}
			}
		}
	}
	&--desktop {
		#{ $header } {
			&__general {
				&_wrapper { height: 94px; }
				&_logo { margin-left: 27px; }
			}
			&__searchform {
				margin-left: 59px;
				position: relative;
				max-width: 400px;  min-width: 300px;
				width: 30vw; height: 34px;
				&_input {
					display: block;
					width: 100%; height: 100%;
					padding: 0 40px 0 15px;
					border-radius: 17.5px;
					border: none;
					font-size: 12px;
					letter-spacing: 0.2px;
				}
				&_submit {
					position: absolute;
					right: 0; top: 0; bottom: 0;
					display: block;
					width: 50px; height: 35px;
					padding: 0;
					margin: auto;
					border: none;
					background-color: transparent;
					background-image: url('./../images/icon-search.svg');
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 18px;
					cursor: pointer;
					&:hover {
						background-image: url('./../images/icon-search-hovered.svg');
					}
				}
			}
			&__location {
				.slick {
					&-arrow {
						top: 16px;
					}
				}
			}
			&__basket {
				margin-left: 30px;
			}
			&__nav {
				&_wrapper {
					height: 50px;
				}
			}
			&__social {
				&_link {
					&:hover {
						svg { fill: $dusty-orange; }
					}
				}
			}
			&__catalog {
				&_button {
					width: 202px; height: 100%;
				}
			}	
		}
	}
	&--mobile { 
		display: none; visibility: hidden; 
		#{ $header } {
			&__general {
				&_wrapper {
					height: 68px;
				}
			}
			&__logo {
				img {
					width: 97px;
					height: 40px;
				}
			}
			&__basket {
				margin-left: 15px;
			}
			&__nav {
				&_wrapper {
					justify-content: center;
					height: 40px;
				}
			}
			&__catalog {
				&_button {
					margin-right: 20px;
					width: 34px;
					height: 34px;
					border-radius: 17px;
				}
			}
			&__location {
				&_block {
					padding-top: 8px;
				}
			}
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {
	.header {
		$header: &;
		&--desktop {
			#{ $header } {
				&__general {
					&_logo {
						margin-left: 16px;
						img {
							height: 45px;
							width: 109px;
						}
					}
				}
				&__searchform {
					margin-left: 34px;
					width: 300px;
				}
				&__basket {
					margin-left: 20px;
				}
				&__catalog {
					&_button {
						min-width: 140px;
						max-width: 140px;
					}
					&_list {
						li {
							a {
								line-height: 1;
							}
						}
					}
				}
				&__nav {
					&_wrapper {

					}
				}
			}
		}
	}
}

@media(max-width: $md-breakpoint) {
	.header {
		$header: &;
		&--desktop { display: none; visibility: hidden; }
		&--mobile { 
			display: block; visibility: visible;
			#{ $header } {

			} 
		}
	}
}

@media(max-width: $sm-breakpoint) {

}

@media(max-width: $xs-breakpoint) {

}