.contacts {
	&__tabs {
		&_handlers {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			height: 39px; 
			margin-bottom: 20px;
			border-radius: 100px;
		}
		&_handler {
			display: flex;
			align-items: center;
			height: 100%;
			padding: 0 20px;
			text-transform: uppercase;
			border: 1px solid $greyish;
			@include uttons-DARK;
			transition: background-color 200ms, border-color 200ms, color 200ms;
			&:hover { color: $dusty-orange; }
			&.active {
				background-color: $dusty-orange;
				border-color: $dusty-orange;
				@include uttons-WHITE;
			}
			&:first-child { border-top-left-radius: 100px; border-bottom-left-radius: 100px; border-right: none; }
			&:last-child { border-top-right-radius: 100px; border-bottom-right-radius: 100px; border-left: none; }
		}
	}	
	&__maps {
		position: relative;
		height: 450px;
		margin-bottom: 80px;
		background-color: #fff;
		&_map {
			&.active {
				display: block;
			}
			display: none;
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {

}

@media(max-width: $md-breakpoint) {
	.contacts {
		&__maps {
			margin-bottom: 40px;
		}
	}
}

@media(max-width: $sm-breakpoint) {

}

@media(max-width: $xs-breakpoint) {
	.contacts {
		&__tabs {
			&_handler {
				padding: 0 15px;
			}
		}
	}
}