*,
*::before,
*::after {
	box-sizing: border-box;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol {
    list-style: none;
    ol, ul {
        padding: 0;
        margin: 0;
    }
}

ul {
    list-style: none;
    ol, ul {
        padding: 0;
        margin: 0;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
	color: inherit;
    text-decoration: none;
    -webkit-text-decoration-skip: objects;
	&:hover { text-decoration: none; }
}

input, textarea, button { outline: none; }