.the_button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 39px; width: 100%;
	padding: 0;
	font: {
		weight: 700;
		size: 14px;
	}
	color: $white;
	line-height: 39px;
	letter-spacing: 0.2px;
	text-transform: uppercase;
	cursor: pointer;
}