.basket {
	&__card {
		display: flex;
		flex-direction: row;
		height: 300px;
		padding: 30px;
		margin-bottom: 20px;
		background-color: #fff;
		&_block {
			display: flex;
			flex-direction: column;
		}
		&_thumbnail {
			display: block;
			max-width: 386px; width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center;
			border: 1px solid $white-two;
			img {
				display: block;
				margin: 0 auto;
				max-width: 100%; 
				height: 100%;
			}
		}
		&_wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
		}
		&_infoblock {
			display: flex;
			max-width: 448px; width: 100%; height: 100%;
			margin-left: 32px;
			flex-direction: column;
			justify-content: space-between;
			&__vendor-code {
				margin-bottom: 15px;
				font-size: 12px;
				letter-spacing: 0.2px;
				color: $darkgrey;
			}
			&__type {
				@include Bodycopy-B4-dark;
			}
			&__name {
				margin-top: 5px;
				font-size: 21px;
				color: $black;
				letter-spacing: 0.3px;
			}
		}
		&_attr {
			&__name {
				@include Bodycopy-B4-dark;
			}
			&__value {
				
			}
		}
		&_calc {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 418px;
			&__counter {
				display: flex;
				flex-direction: row;
				* {
					width: 34px; height: 34px;
				}
				button {
					background-color: transparent;
					background-repeat: no-repeat;
					background-position: center;
					border: none;
					padding: 0;
					text-indent: -9999px;
					cursor: pointer;
					&.reduce {
						background-image: url('./../images/icon-minus.png');
						background-size: 12px 2px;
					}
					&.add {
						background-image: url('./../images/icon-plus.svg');
						background-size: 12px;
					}
				}
				span {
					display: flex;
					align-items: center;
					justify-content: center;
					border: solid 1px $greyish;
					font-size: 14px;
					color: #393939;
				}
			}
			&__price {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: flex-end;
			}
			&__sum {
				font: {
					weight: 700;
					size: 21px;
				}
				letter-spacing: 0.3px;
			}
			&__quantity {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				margin-bottom: 5px;
				font-size: 14px;
			}
			&__factor {
				margin-right: 5px;
			}
			&__price {

			}
		}
		&_delete {
			background-color: transparent;
			border: none;
			font-size: 12px;
			letter-spacing: 0.2px;
			color: $darkgrey;
			cursor: pointer;
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {
	.basket__card {
		&_calc {
			max-width: 320px; width: 100%;
		}
	}
}

@media(max-width: $md-breakpoint) {
	.basket__card {
		flex-wrap: wrap;
		height: auto;
		padding-bottom: 50px;
		&_infoblock {
			margin-top: 20px;
			margin-left: 0;
		}
		&_calc {
			max-width: 270px;
		}
	}
}

@media(max-width: $sm-breakpoint) {
	.basket__card {
		padding: 20px;
		padding-bottom: 50px;
		&_wrapper {
			flex-direction: column;
		}
		&_calc {
			margin-top: 25px;
			&__counter {
				margin-top: 17px;
			}
			&__sum {
				margin-top: 17px;
			}
		}
		&_delete {
			margin-top: 40px;
		}
	}
}

@media(max-width: $xs-breakpoint) {

}