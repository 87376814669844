// Variables
@import './variables';
// Functions
@import './mixins';

.content {
	position: relative;
	// Text field
	&-text {
		h1, h2, h3, p, li {
			color: $color;
		}
		ol ol, ol ul, ul ol, ul ul {
			padding-top: 0;
		}
		a {
			color: $general-color;
			@include transition;
			&:hover, &focus {
				color: #999;
			}
		}
		ol {
			> li {
				list-style-type: decimal;
			}
		}
		ul {
			> li {
				position: relative;
				&:before {
					content: '–';
					position: absolute;
				}
			}
		}
		img {
			width: 100%;
			height: auto;
			-o-object-fit: cover;
			object-fit: cover;
		}
	}
	// Quote
	.quote {
		margin-top: $indent;
		position: relative;
		border-radius: 5px;
		padding: 20px 25px;
		&::before {
			content: '';
			position: absolute;
			left: 0; top: 0;
			width: 6px; height: 100%;
			background-color: $general-color;
		}
		&-text {
			@include font;
		}
	}
	// Files
	.files {
		margin-top: $indent;
		.file {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			&-thumbnail {
				display: inline-block;
				min-width: 33px;
				width: 33px;
				height: 40px;
				> svg {
					width: 100%;
					height: 100%;
				}
			}
			&-info {
				display: inline-block;
				padding: 0 0 0 15px;
				.name {
					display: block;
					font: {
						weight: 400;
						size: 16px;
					}
					line-height: 22px;
					color: #333;
					em {
						font-style: normal;
						border: 1px solid rgba(0,0,0,0);
						@include transition;
					}
				}
				.size {
					display: block;
					color: #999;
					text-transform: uppercase;
				}
			}
			&:hover .file-info {
				.name {
					color: $general-color;
					em {
						border-bottom-color: rgba($general-color, 0.3)
					}
				}
			}
		}
	}
	// Table
	.table {
		margin-top: $indent;
		&-responsive {
			border: 1px solid $border-color;
			border-top: 0;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}
		h3 {
			&.table-title {
				color: #fff;
				padding: 12px 31px;
				border: 0;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				background-color: $general-color;
			}
		}
		&-text {
			table {
				th, td {
					padding: 10px 30px;
					border-right: 1px solid $border-color;
					border-bottom: 1px solid $border-color;
					&:last-child {
						border-right: 0;
					}
				}
				thead {
					tr {
						th {
							color: #999;
						}
					}
				}
				tbody {
					tr {
						td {
							color: #333;
							&:first-child {
								min-width: 480px;
							}
							&:last-child {
								min-width: 290px;
							}
						}
						&:last-child td {
							border-bottom: 0;
						}
					}
				}
			}
		}
	}
	// Sertificates
	.certificates {
		margin-top: $indent;
		.certificate {
			&-thumbnail {
				position: relative;
				display: block;
				width: calc(100% - 24px);
				height: 174px;
				border: 1px solid $border-color;
				border-bottom-color: #d9d9d9;
				@include background($position: center);
				&::before {
					content: '';
					position: absolute;
					right: -27px;
					bottom: 0;
					width: 26px;
					height: 36px;
					border-top: 36px solid #d9d9d9;
					border-right: 26px solid transparent;
				}
				&::after {
					@extend .on_lightbox;
				}
				&:hover {
					&::after { opacity: 1; }
				}
			}
			&-sign {
				p {
					display: block;
					color: #999;
				}
			}
		}
	}
	// Gallery
	.gallery {
		margin-top: $indent;
		.item {
			&-thumbnail {
				position: relative;
				display: block;
				width: 100%;
				height: auto;
				border: 1px solid $border-color;
				@include background();
				&::after {
					@extend .on_lightbox;
				}
				&:hover {
					&::after { opacity: 1; }
				}
			}
			&-sign {
				p {
					display: block;
					color: #999;
				}
			}
		}
	}
}
