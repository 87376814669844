@font-face {
	font-family: "OpenSans";
	src: url("../fonts/OpenSansRegular/OpenSansRegular.eot");
	src: url("../fonts/OpenSansRegular/OpenSansRegular.eot?#iefix")format("embedded-opentype"), url("../fonts/OpenSansRegular/OpenSansRegular.woff") format("woff"), url("../fonts/OpenSansRegular/OpenSansRegular.ttf") format("truetype");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "OpenSans";
	src: url("../fonts/OpenSansLight/OpenSansLight.eot");
	src: url("../fonts/OpenSansLight/OpenSansLight.eot?#iefix")format("embedded-opentype"), url("../fonts/OpenSansLight/OpenSansLight.woff") format("woff"), url("../fonts/OpenSansLight/OpenSansLight.ttf") format("truetype");
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: "OpenSans";
	src: url("../fonts/OpenSansBold/OpenSansBold.eot");
	src: url("../fonts/OpenSansBold/OpenSansBold.eot?#iefix")format("embedded-opentype"), url("../fonts/OpenSansBold/OpenSansBold.woff") format("woff"), url("../fonts/OpenSansBold/OpenSansBold.ttf") format("truetype");
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "OpenSans";
	src: url("../fonts/OpenSansExtraBold/OpenSansExtraBold.eot");
	src: url("../fonts/OpenSansExtraBold/OpenSansExtraBold.eot?#iefix")format("embedded-opentype"), url("../fonts/OpenSansExtraBold/OpenSansExtraBold.woff") format("woff"), url("../fonts/OpenSansExtraBold/OpenSansExtraBold.ttf") format("truetype");
	font-style: normal;
	font-weight: 800;
}


@font-face {
	font-family: "OpenSans";
	src: url("../fonts/OpenSansSemiBold/OpenSansSemiBold.eot");
	src: url("../fonts/OpenSansSemiBold/OpenSansSemiBold.eot?#iefix")format("embedded-opentype"), url("../fonts/OpenSansSemiBold/OpenSansSemiBold.woff") format("woff"), url("../fonts/OpenSansSemiBold/OpenSansSemiBold.ttf") format("truetype");
	font-style: normal;
	font-weight: 600;
}
