.mobile__menu {
	position: fixed;
	top: 0; left: 0;
	display: flex;
	flex-direction: column;
	width: 320px; height: 100%;
	transform: translateX(-100%);
	z-index: 99;
	background-color: #fff;
	@include transition;
	&.active { transform: translateX(0); }
	&--inner {
		top: 70px;
		display: flex;
		flex-direction: column;
		li {
			border-bottom: 1px solid $white-two;
			&:hover {
				a { color: $dusty-orange; }
				svg { fill: $dusty-orange; }
			}
			&.pointer, &.current-page {
				> a {
					font: {
						weight: 700;
						size: 14px;
					}
				}
			}
			&.pointer { 
				> a {
					text-transform: uppercase; 
				}
			}
			a {
				display: block;
				padding: 17px 13px;
				transition: color 200ms;
				@include Bodycopy-B4-dark;
			}
		}
		&.active { display: flex!important; }
	}
	&_header { padding: 17px 13px; }
	&_closer {
		position: relative;
		display: flex;
		align-items: center; justify-content: center;
		width: 34px; height: 34px;
		padding: 0;
		border: none;
		border-radius: 50%;
		background-color: $camo-green;
		cursor: pointer;
		&::before, &::after {
			content: '';
			position: absolute;
			height: 2px; width: 14px;
			background-color: #fff;
		}
		&::before {
			transform: rotate(45deg);
		}
		&::after {
			transform: rotate(-45deg);
		}
	}
	&_body {
		overflow: auto;
		padding-bottom: 30px;
	}
	&_nav {
		&__list {
			display: flex;
			flex-direction: column;
			li {
				border-bottom: 1px solid $white-two;
				&:hover {
					a { color: $dusty-orange; }
					svg { fill: $dusty-orange; }
				}
				&.pointer, &.current-page {
					> a {
						font: {
							weight: 700;
							size: 14px;
						}
					}
				}
				&.pointer { 
					> a {
						text-transform: uppercase; 
					}
				}
				a {
					display: block;
					padding: 17px 13px;
					transition: color 200ms;
					@include Bodycopy-B4-dark;
				}
			}
		}
	}
	&_social {
		margin-left: 0;
		padding: 40px 0 0 13px;
		&_link {
			border: 1px solid $black;
			transition: border-color 200ms;
			svg {
				fill: $black;
			}
			&:hover {
				border-color: $dusty-orange;
				svg {
					fill: $dusty-orange;
				}
			}
		}
	}
}