.card {
	.slick {
		&-slide {
			img {
				max-width: 100%;
				margin: 0 auto;
			}
		}
		&-arrow {
			position: absolute; z-index: 1;
			top: 45%;
			width: 28px; height: 28px;
			border-radius: 50%;
			background-color: $white;
			transition: box-shadow 200ms;
			border: none;
			cursor: pointer;
			svg {
				fill: #888888;
			}
			&:hover {
				box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
				svg {
					fill: #e5692d;
				}
			}
		}
		&-next {
			right: 0;
		}
		&-prev {
			left: 0;
		}

		&-dots {
			position: absolute;
			bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			li {
				width: 12px; height: 12px;
				&:not(:first-child) { margin-left: 10px; }
				button {
					text-indent: -9999px;
					width: 12px; height: 12px;
					border-radius: 50%;
					border: solid 1px $black;
					background-color: transparent;
					transition: background-color 200ms;
					padding: 0;
					cursor: pointer;
				}
				&.slick-active {
					button {
						background-color: $black;
					}
				}
			}
		}
	}
	&__wrapper {
		border-bottom: 1px solid $pinkish-grey;
		padding: 40px 0;
	}
	&__heading {
		margin-top: 40px;
		align-items: center;
		&_articul {
			font-size: 12px;
			letter-spacing: 0.2px;
			color: $greyish;
		}
		&_wrapper {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
		&_brand {
			width: 90px; height: 40px;
			margin-bottom: 10px;
			background-color: #fff;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
	}

	&__slider {
		height: 405px;
		&_nav {
			height: 120px;
			padding-top: 20px;
			background-color: transparent;
			.card__slider_slide {
				height: 100px;
				img {
					height: 100px;
				}
			}
			.slick-slide {
				height: 100px;
				margin-left: 18px;
			}
		}
	}

	&__infoblock {
		&_actions {
			padding: 30px;
			padding-top: 20px;
			background-color: #fff;
		}
		&_price {
			margin-bottom: 20px;
			&--current {
				font: {
					weight: 700;
					size: 32px;
				}
				color: $black;
				letter-spacing: 0.4px;
			}
			&--oldest {
				margin-left: 20px;
				font-size: 21px;
				letter-spacing: 0.3px;
				color: $greyish;
			}
		}
		&_buttons {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-wrap: wrap;
			&--incart {
				position: relative;
				max-width: 130px;
				background-color: $dusty-orange;
				border: none;
				transition: background-color 200ms;
				&:hover { background-color: #d14908; }
				&::before {
					content: attr(data-name);
					position: absolute;
					left: 0; top: 0;
					width: 100%; height: 100%;
					font-weight: 700;
					font-size: 14px;
					color: #fff;
					line-height: 39px;
					letter-spacing: 0.2px;
				}
			}
			&--incredit {
				max-width: 110px;
				margin-left: 20px;
				border: solid 1px $darkgrey;
				background-color: transparent;
				color: $black;
				transition: color 200ms;
				&:hover {
					color: #fff;
					background-color: $dusty-orange;
					border-color: $dusty-orange;
				}
			}
			&--onclick {
				height: 39px;
				margin-left: 20px;
				font: {
					weight: 700;
					size: 14px;
				}
				text-transform: uppercase;
				color: #e5692d;
				background-color: transparent;
				border: none;
				cursor: pointer;
			}
		}
		&_tagging {
			display: block;
			margin: 10px 0;
			@include Bodycopy-links-dark;
		}
		&_attrs {
			display: flex;
			flex-direction: column;
		}
		&_attr {
			display: flex;
			flex-direction: row;
			font-size: 14px;
			margin-bottom: 10px;
			&__name {
				color: $greyish;
			}
			&__value {
				color: $black;
				margin-left: 3px;
			}
		}
		&_anchor {
			@include Bodycopy-links-orange;
			text-decoration: underline;
		}
		&_variatives {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 30px 0;
			&__name {
				@include Bodycopy-B4-dark;	
			}
			&__values {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-left: 10px;
			}
			&__value {
				width: 25px; height: 25px;
				border: solid 1px #888888;
				border-radius: 50%;
				cursor: pointer;
				&.current {
					border-color: #e5692d;
				}
				&:not(:first-child) {
					margin-left: 15px;
				}
			}
		}
		&_status {
			position: relative;
			display: block;
			margin-bottom: 10px;
			padding-left: 15px;
			@include Bodycopy-B4-dark;
			&::before {
				content: '';
				position: absolute;
				top: 0; bottom: 0; left: 0;
				margin: auto;
				width: 9px; height: 9px;
				border-radius: 4.5px;
			}
			&--in_stock {
				&::before {
					background-color: #0e9f06;
				}
			}
			&--to_order {
				&::before {
					background-color: #f8b800;
				}
			}
			&--not_available {
				&::before {
					background-color: tomato;
				}
			}
		}
		&_adresses {
			display: flex;
			flex-direction: column;
		}
		&_adress {
			@include Bodycopy-links-dark;
		}
	}

	&__characters {
		&_attrs {
			display: flex;
			flex-direction: column;
		}
		&_attr {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 15px 0;
			&:first-child { padding-top: 0; }
			&:not(:last-child) { border-bottom: 1px solid $pinkish-grey; }
			&__name {
				@include Bodycopy-B4-dark;
			}
			&__value {
				font-size: 14px;
				color: $black;
			}
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {
	.card__infoblock_buttons--onclick {
		width: 100%;
		text-align: left;
		margin-left: 0;
		margin-top: 10px;
	}
	.card__infoblock_attrs {
		display: none;
	}
	.card__heading_brand {
		display: none;
	}
}

@media(max-width: $md-breakpoint) {
	.card {
		&__slider {
			height: 336px;
			&_slide {
				height: 336px;
			}
			&_nav {
				display: none;
			}
		}
		&__infoblock {
			margin-top: 20px;
		}
	}
}

@media(max-width: $sm-breakpoint) {
	.card {
		&__wrapper {
			&:first-child {
				padding-top: 0;
			}
		}
		&__infoblock {
			&_actions {
				padding: 20px;
			}
			&_price {
				align-items: center;
				&--current {
					font-size: 21px;
				}
				&--oldest {
					font-size: 18px;
				}
			}
			&_buttons {
				display: flex;
				flex-direction: row;
				align-items: center;
				&--incredit {
					margin-left: 10px;
				}
				
			}
		}
	}
}

@media(max-width: $xs-breakpoint) {
	.card {
		&__heading {
			flex-direction: column;
			align-items: flex-start;
			&_articul {
				display: block;
				margin-top: 10px;
			}
		}
	}
}