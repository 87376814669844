@mixin transition($time: 0.2, $type: all) {
	transition: $type #{$time}s;
}

@mixin background($size: cover, $position: center) {
	background-repeat: no-repeat;
	background-position: $position;
	background-size: $size;
}

@mixin font($weight: 400, $size: 16px, $line-height: normal, $family: $general-font) {
	font: {
		family: $family;
		weight: $weight;
		size: $size;
	}
	line-height: $line-height;
}