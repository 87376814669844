/*	The sidebar
**************************/
.sidebar {
	ul {
		position: relative;
		border: 1px solid $border-color;
		border-radius: 5px;
		overflow: hidden;
		> li:not(:last-child) {
			> a {
				&::after {
					content: "";
					position: absolute;
					display: block;
					margin: 0 auto;
					top: 100%;
					left: 0;
					right: 0;
					height: 1px;
					width: 210px;
					background-color: #eaeaea;
				}
			}
		}
		> li {
			display: block;
			> a {
				display: block;
				position: relative;
				font: {
					weight: 400;
					size: 14px;
				}
				color: #333;
				padding: 13px 29px;
				@include transition();
			}
			&:hover a {
				color: $general-color;
			}
			&.current-menu-item a {
				color: #fff;
				background-color: $general-color;
			}
		}
	}
}