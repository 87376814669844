.catalog {
	&__banners {
		margin-top: 20px;
		&_item {
			display: flex!important;
		}
	}

	&__filters {
		&_button {
			max-width: 127px;
			border-radius: 19.5px;
			background-color: $dusty-orange;
			transition: background-color 200ms;
			border: none;
			svg {
				margin-left: 10px;
				fill: #fff;
			}
			&:hover {
				background-color: #d14908;
			}
		}
	}
	&__sorting {
		display: none;
		flex-direction: row;
		justify-content: space-between;
		&_params {
			margin-left: 20px;
			padding-right: 15px;
			background-color: transparent;
			border: none;
			outline: none;
			@include Bodycopy-B4-dark;
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {

}

@media(max-width: $md-breakpoint) {
	.catalog {
		&__banners {
			&_item {
				display: none!important;
			}
		}
		&__sorting {
			display: flex;
		}
	}
}

@media(max-width: $sm-breakpoint) {
	.catalog {
		&__sorting {
			&_params {
				font-size: 14px;
			}
		}
	}
}

@media(max-width: $xs-breakpoint) {

}