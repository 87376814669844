.actions__card {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 208px;
	padding: 20px;
	background-repeat: no-repeat;
	background-position: center;
	&::before {
		content: '';
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		background-color: rgba(#000, .5);
		z-index: 1;
	}
	&--incard {
		height: 343px;
	}
	&--daily {
		background-color: $dull-orange;
		background-position: center right 20px;
		&::before {
			content: none;
		}
	}
	&:not(:last-child) {
		margin-bottom: 20px;
	}
	&_heading {
		position: relative; z-index: 1;
		display: flex;
		flex-direction: column;
	}
	&_status {
		font-size: 14px;
		color: $white;
		letter-spacing: 0.2px;
		text-transform: uppercase;
	}
	&_name {
		display: block;
		max-width: 150px;
		margin-top: 5px;
		font-weight: 700;
		color: #fff;
	}
	&_price {
		position: relative; z-index: 1;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		color: $white;
		letter-spacing: 0.2px;
		&--current {
			font-weight: 700;
		}
		&--oldest {
			margin-left: 7px;
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {

}

@media(max-width: $md-breakpoint) {
	
}

@media(max-width: $sm-breakpoint) {

}

@media(max-width: $xs-breakpoint) {

}