
html { 
	height: 100%;
}

body {
	position: relative;
	height: auto;
	line-height: normal;
	color: #333;
	height: 100%;
	background-color: $white-two;
	&.active { overflow: hidden; }
	* { font-family: $general-font; }
}

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.main {
	flex: 1 0 auto;
	overflow: hidden;
	&__heading {
		margin-bottom: 20px;
		&_name {
			@include -Headline-H2-black;
		}
	}
	&__link {
		display: flex;
		align-items: center; justify-content: center;
		margin-bottom: 40px;
		a {
			padding: 10px 20px;
			border: solid 1px $darkgrey;
			text-transform: uppercase;
			transition: border-color 200ms, color 200ms, background-color 200ms;
			@include uttons-DARK;
			&:hover {
				border-color: $dusty-orange;
				background-color: $dusty-orange;
				color: $white;
			}
		}
	}
	&__article {
		&_time {
			display: block;
			margin-bottom: 10px;
			font-size: 14px;
			color: $darkgrey;
		}
	}
	&__banners {
		margin: 20px 0 40px;
		&__action_card {
			
		}
	}
	&__action {
		&_banner {
			position: relative;
			height: 300px;
			margin-bottom: 40px;
			background-repeat: no-repeat;
			background-position: center;
			&::before {
				content: '';
				position: absolute;
				top: 0; left: 0;
				background-color: rgba(#000, .5);
				width: 100%; height: 100%;
			}
			&__block {
				position: relative; z-index: 1;
				display: flex;
				flex-direction: column;
				width: 100%; height: 100%;
				padding-top: 50px;
			}
			&__title {
				display: block;
				margin-bottom: 10px;
				font: {
					weight: 700;
					size: 48px;
				}
				letter-spacing: 0.6px;
				color: $white;
			}
			&__description {
				font-size: 18px;
				color: $white;
				letter-spacing: 0.2px;
			}
			&__link {
				margin-top: 30px;
				max-width: 130px;
				background-color: $dusty-orange;
				transition: background-color 200ms;
				&:hover {
					background-color: #d14908;
				}
			}
		}
	}
}

.footer { flex: 0 0 auto; }

.page {
	padding-bottom: 60px;
	&__heading {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		&_name {
			font-size: 32px;
			font-weight: 400;
			letter-spacing: 0.4px;
		}
		&_link {
			
		}
	}
	&__single {
		
		&_description {
			display: block;
			margin-bottom: 40px;
			@include Bodycopy-B3-dark;
			font-weight: 400;
		}
		&_content {
			padding-bottom: 20px;
			ul li, ol li, h3, p {
				display: block;
				margin-bottom: 20px;
				@include Bodycopy-B4-dark;
			}	
			ul li {
				position: relative;
				text-indent: 20px;
				&::before {
					content: '';
					position: absolute;
					top: 8px; left: 0;
					margin: auto;
					width: 4px; height: 4px;
					border-radius: 2px;
					background-color: #393939;
				}
			}
		}
		&_other {
			border-top: 1px solid $pinkish-grey;
			padding-top: 40px;
		}
		
	}
}

.body_shadow {
	position: fixed;
	top: 0; left: 0;
	width: calc(100% - 320px); height: 100%;
	background-color: rgba( $color-black, .7 );
	transform: translateX(-100%);
	z-index: 99;
	@include transition;
	&.active { transform: translateX(0); }
}

.lightbox__image {
	display: block;
	position: relative;
	&::after {
		content: '';
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: calc(100% - 4px);
		background-color: rgba(#000, .5);
		opacity: 0;
		@include transition;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		margin: auto;
		background-image: url('./../images/zoom.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 29px;
		height: 29px; width: 29px;
		opacity: 0;
		z-index: 30;
		@include transition;
	}
	&:hover {
		&::after { opacity: 1; }
		&::before { opacity: 1; }
	}
}


.slick {
	&-arrow {
		position: absolute;
		background-repeat: no-repeat;
		background-position: center;
		cursor: pointer;
	}
	&-next {
		transform: rotate(180deg);
	}
}

.slick-dots {
	button {
		text-indent: -9999px;
	}
}


.social__block {
	display: flex;
	flex-direction: row;
	margin-left: 30px;
	&_link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 34px; height: 34px;
		border-radius: 17px;
		border: 1px solid $white;
		background-color: transparent;
		transition: background-color 200ms;
		&:hover {
			background-color: $white;
		}
		svg { 
			fill: $white;
			transition: fill 200ms;
		}
		&:not(:first-child) { margin-left: 15px; }
	}
}



.on_lightbox {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: rgba($color-black, .7);
	background-image: url('./../images/zoom.svg');
	background-size: 30px 30px;
	@include transition;
}

.logo {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 56px;
	img {
		display: block;
		width: 84px; height: inherit;
	}
	span {
		margin-left: 16px;
		font: {
			weight: 700;
			size: 34px;
		}
		color: $color-black;
	}
}

.the_title {
	display: block;
	margin-bottom: 20px;
	font: {
		weight: 900;
		size: 50px;
	}
	line-height: 50px;
	letter-spacing: -2.5px;
	
	&.bigger {
		font-size: 60px;
		line-height: 60px;
	}
}

.the_title_description {
	font-size: 20px;
	line-height: 30px;
}

.menu {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	li {
		display: flex;
		align-items: center;
		height: 100%;
		&:not(:first-child) { margin-left: 37px; }
		a {
			position: relative;
			display: flex;
			align-items: center;
			height: 100%;
			font-size: 18px;
			color: #262626;
			&::after {
				content: '';
				position: absolute; 
				left: 0; top: 60%;
				height: 1px; width: 100%;
				background-color: #262626;
				opacity: 0;
				@include transition;
			}
		}
		&:hover, &.current-page {
			a {
				&::after {
					opacity: 1;
				}
			}
		}
	}
}

.nano-pane { display: none!important; }

.modal_handler {
	position: fixed;
	display: flex;
	justify-content: center;
	top: 50%; right: -100px;
	width: 250px; height: 50px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border: none;
	background-color: $general-color;
	font: {
		weight: 700;
		size: 22px;
	}
	color: $white;
	@include transition;
	transform: rotate(-90deg);
	cursor: pointer;
	&:hover { background-color: #2e8c9a; }
	&:active { background-color: #0a5c68; }
}

::-webkit-input-placeholder {color:$pinkish-grey!important;}
::-moz-placeholder          {color:$pinkish-grey!important;}
:-moz-placeholder           {color:$pinkish-grey!important;}
:-ms-input-placeholder      {color:$pinkish-grey!important;}

#map {
	width: 100%; height: 100%;
	> ymaps {
		width: 100%; height: 100%;
		> ymaps {
			width: 100%; height: 100%;
			> ymaps {
				width: 100%; height: 100%;
			}
		}
	}
}

label.current {
	&::before {
		background-image: url('./../images/icon-check.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 19px 13px;
	}
}

.ymaps-2-1-59-balloon__tail:after {
	content: none!important;
}

@media(max-width: $lg-breakpoint - 1) {
	.container {
		max-width: 960px!important;
	}
	.social__block {
		margin-left: 20px;
	}
	
	.main {
		&__banners {
			&__action_card {
				display: none!important;
			}
		}
		&__action {
			&_banner {
				&__title {
					max-width: 440px;
					font-size: 36px; 
				}
			}
		}
	}
}

@media(max-width: $md-breakpoint) {
	.page {
		&__heading {
			&_sorting { display: none; }
		}
		
	}
}

@media(max-width: $sm-breakpoint) {
	.main {
		&__heading {
			&_name {
				font-size: 18px;
			}
		}
	}
	.page {
		&__heading {
			align-items: center;
			&_name {
				font-size: 21px;
			}
			
		}
	}
}

@media(max-width: $xs-breakpoint) {
	.main {
		&__action {
			&_banner {
				&__block {
					padding: 20px;
					padding-top: 40px;
				}
				&__title {
					font-size: 28px;
					margin-bottom: 20px;
				}
				&__description {
					font-size: 16px;
				}
			}
		}
	}
}