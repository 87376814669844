.basket {
	padding-top: 50px;
	&__heading {
		margin-bottom: 35px;
	}
	&__link {
		max-width: 90px;
		border: 1px solid $darkgrey;
		@include uttons-DARK;
		transition: color 200ms;
		&:hover {
			color: #fff;
			background-color: $dusty-orange;
			border-color: $dusty-orange;
		}
	}
	&__result {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin-top: 20px;
		&_wrapper {
			
		}
		&_text {
			@include -Headline-H2-black;
		}
		&_price {
			margin-left: 8px;
			font: {
				weight: 700;
				size: 32px;
			}
			letter-spacing: 0.4px;
			color: $black;
		}
		&_link {
			margin-top: 30px;
			background-color: $dusty-orange;
			transition: background-color 200ms;
			max-width: 179px;
			&:hover {
				background-color: #d14908;
			}
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {

}

@media(max-width: $md-breakpoint) {
	
}

@media(max-width: $sm-breakpoint) {
	.basket {
		padding-top: 37px;
		&__result {
			&_price {
				font-size: 21px;
			}
		}
	}
}

@media(max-width: $xs-breakpoint) {

}