.mobile {
	&__search {
		position: fixed;
		top: 0; left: 0;
		flex-direction: column; 
		width: 100%; height: 100%; 
		background-color: $white;
		z-index: 10;
		transform: translateX(100%);
		transition: transform 200ms;
		&.active {
			transform: translateX(0);
		}
		&_header, &_body { display: flex; justify-content: flex-end; padding: 17px 13px; }
		&_closer {
			position: relative;
			display: flex;
			align-items: center; justify-content: center;
			width: 34px; height: 34px;
			padding: 0;
			border: none;
			border-radius: 50%;
			background-color: transparent;
			border: solid 1px #cfcfcf;
			cursor: pointer;
			&::before, &::after {
				content: '';
				position: absolute;
				height: 2px; width: 14px;
				background-color: #393939;
			}
			&::before {
				transform: rotate(45deg);
			}
			&::after {
				transform: rotate(-45deg);
			}
		}
	}
	&__searchform {
		position: relative;
		height: 34px; width: 100%;
		border: solid 1px #cfcfcf;
		border-radius: 17.5px;
		&_input {
			display: block;
			width: 100%; height: 100%;
			padding: 0 40px 0 15px;
			border-radius: 17.5px;
			border: none;
			font-size: 12px;
			letter-spacing: 0.2px;
		}
		&_submit {
			position: absolute;
			right: 0; top: 0; bottom: 0;
			display: block;
			width: 50px; height: 35px;
			padding: 0;
			margin: auto;
			border: none;
			background-color: transparent;
			background-image: url('./../images/icon-search.svg');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 18px;
			cursor: pointer;
			&:hover {
				background-image: url('./../images/icon-search-hovered.svg');
			}
		}
	}
}