.articles {
	&__card {
		$card: &;
		&--news {
			display: flex;
			flex-direction: column;
			background-color: $white;
			height: 470px;
			margin-bottom: 30px;
			transition: transform 200ms, box-shadow 200ms;
			&:hover {
				box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
				#{ $card }_title {
					color: $dusty-orange;
				}
			}
			#{ $card } {
				&_thumbnail {
					height: 352px;
					background-repeat: no-repeat;
					background-position: center;
				}
				&_infoblock {
					display: flex;
					flex-direction: column;
					padding: 20px;
					padding-bottom: 0;
				}
				&_title {
					display: block;
					margin-bottom: 7px;
					transition: color 200ms;
					@include -Headline-H2-black;
				}
				&_description {
					@include Bodycopy-B4-dark;
				}
			}
		}
		&--contacts {
			height: auto;
			#{ $card } {
				&_infoblock {
					padding-bottom: 20px;
				}
				&_title {
					@include Bodycopy-B3-dark;
				}
			}
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {

}

@media(max-width: $md-breakpoint) {
	
}

@media(max-width: $sm-breakpoint) {

}

@media(max-width: $xs-breakpoint) {
	.articles__card {
		$card: &;
		&--news {
			#{ $card } {
				&_infoblock {
					padding-bottom: 20px;
				}
				&_title {
					font-size: 18px;
				}
			}
		}
	}
}