.card__category {
	$this: &;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	transition: box-shadow 200ms;
	margin-bottom: 20px;
	&:hover {
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		#{ $this }_infoblock {
			&__name {
				color: $dusty-orange;
			}
		}
	}
	&_thumbnail {
		display: block;
		height: 170px;
	}
	&_infoblock {
		display: flex;
		align-items: center;
		padding: 0 20px;
		width: 100%; height: 57px;
		&__name {
			letter-spacing: 0.2px;
			transition: color 200ms;
		}
	}
}

@media(max-width: $md-breakpoint) {
	.card__category {
		display: flex;
		flex-direction: row;
		height: 124px;
		&_thumbnail {
			min-width: 130px;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		&_infoblock {
			height: 100%;
		}
	}
}

@media(max-width: $sm-breakpoint) {
	.card__category {

	}
}

@media(max-width: $xs-breakpoint) {

}