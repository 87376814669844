.main {
	&__brands {
		margin-bottom: 40px;
		&_slider {
			height: 70px;
			background-color: $white;
			&__slide {
				position: relative;
				display: flex!important;
				align-items: center; justify-content: center;
				height: 70px;
				&::before {
					content: '';
					position: absolute;
					top: 0; bottom: 0; left: 0;
					margin: auto;
					width: 1px; height: 30px;
					background-color: $white-two;
				}
				img { margin: auto; }
			}
			.slick {
				&-arrow {
					position: absolute;
					top: 41%;
					border: none;
					text-indent: -9999px;
					background-color: transparent;
					background-image: url('./../images/icon-slider-arrow.svg');
					background-size: 8px 13px;
				}
				&-prev {
					left: -20px;
				}
				&-next {
					right: -20px;
					transform: rotate(180deg);
				}
			}
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {

}

@media(max-width: $md-breakpoint) {
	.main {
		&__brands {
			&_slider {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				height: auto;
				&__slide {
					flex: 0 0 50%;
					&::before { content: none; }
					&:not(:nth-child(5n)),
					&:not(:nth-child(6n)) {
						border-bottom: 1px solid $white-two;
					}
					&:nth-child(2n) {
						border-left: 1px solid $white-two;
					}
				}
			}
		}
	}
}

@media(max-width: $sm-breakpoint) {

}

@media(max-width: $xs-breakpoint) {
	.main {
		&__brands {
			&_slider {
				&__slide {
					flex: 0 0 100%;
					&:not(:last-child) {
						border-bottom: 1px solid $white-two;
					}
					&:nth-child(2n) {
						border-left: none;
					}
				}
			}
		}
	}
}