.footer {
	$footer: &;
	height: 381px;
	padding: 50px 0 60px;
	background-color: $camo-green;
	&__col {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}
	&--desktop {
		#{ $footer } {

		}
	}
	&--mobile {
		display: none;
		visibility: hidden;
		padding: 30px 0;
		#{ $footer } {
			&__social { margin-top: 30px; }
			&__copyright { margin-top: 30px; }
			&__accordion {
				&_list {
					margin-bottom: 40px;
					> li {
						display: flex;
						flex-direction: column;
						border-bottom: 1px solid $greyish-brown;
						> a {
							display: flex;
							align-items: center;
							padding: 20px 0;
							font: {
								weight: 700;
								size: 14px;
							}
							color: $white;
							> svg {
								fill: $white;
								margin-left: 12px;
								transform: rotate(90deg);
							}
						}
					}
				}
				&_sublist {
					display: none;
					li {
						margin-bottom: 15px;
						a {
							font-size: 14px;
							color: #fff;
						}
					}
				}
			}
		}
	}
	
	&__nav {
		&_title {
			display: block;
			font: {
				weight: 700;
				size: 14px;
			}
			color: #fff;
			&:not(:last-child) { margin-bottom: 18px; }
		}
		&_list {
			li {
				&:not(:last-child) { margin-bottom: 10px; }
				a, span {
					font-size: 13px;
					color: rgba(#fff, .7);
				}
			}
		}
	}
	&__social {
		margin-left: 0;
		&_link {
			&:hover {
				svg { fill: $camo-green; }
			}
		}
	}
	&__copyright {
		&_text {
			opacity: .5;
			font-size: 13px;
			letter-spacing: -0.2px;
			color: #fff;
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {

}

@media(max-width: $md-breakpoint) {
	.footer {
		height: auto;
		&__col {
			padding-bottom: 30px;
		}
	}
}

@media(max-width: $sm-breakpoint) {
	.footer {
		&--desktop {
			display: none;
			visibility: hidden;
		}
		&--mobile {
			display: block;
			visibility: visible;
		}
	}
}

@media(max-width: $xs-breakpoint) {

}