/*	The breadcrumbs
**************************/
.breadcrumbs {
	padding: 20px 0;
	ul {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		li {
			position: relative;
			a {
				@include Bodycopy-B4-dark;
			}
			&:after {
				position: absolute;
				top: 2px; bottom: 0; right: -25px;
				display: block;
				content: '';
				margin: auto 7px;
				width: 5px; height: 7px;
				background-image: url('./../images/icon-bc-arrow.svg');
				background-repeat: no-repeat;
				background-position: center;
				background-size: 5px 7px;
			}
			&:last-child:after { display: none; }
			&:not(:last-child) { margin-right: 30px; }
		}
	}
	+ .text {
		margin: 0;
	}
}