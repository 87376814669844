.container,
.container-fluid,
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
	box-sizing: border-box;
	padding-left: $padding-left;
	padding-right: $padding-right;
}

.container {
	max-width: $container-max-width;
	width: 100%; 
	margin: 0 auto;
}

.container-fluid {
	max-width: 100%;
	width: 100%;
	margin: 0 auto;
}

.row {
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-left: $margin-left;
	margin-right: $margin-right;
}

.col {
	display: flex;
	flex: 1 1 0;
}

// orders
@for $i from 1 through 12 {
	.order-#{$i} {
		order: $i;
	}
}

@for $i from 1 through 12 {
	.col-#{$i} {
		flex-basis: $width_value * $i!important;
		max-width: $width_value * $i!important;
	}
}

@for $i from 1 through 11 {
	.col-offset-#{$i} {
		margin-left: $width_value * $i!important;
	}
}

@media (min-width: $xs-breakpoint) {
	@for $i from 1 through 12 {
		.col-xs-#{$i} {
			flex-basis: $width_value * $i!important;
			max-width: $width_value * $i!important;
		}
	}

	@for $i from 1 through 11 {
		.col-xs-offset-#{$i} {
			margin-left: $width_value * $i!important;
		}
	}
}

@media (min-width: $sm-breakpoint) {
	
	@for $i from 1 through 12 {
		.col-sm-#{$i} {
			flex-basis: $width_value * $i!important;
			max-width: $width_value * $i!important;
		}
	}

	@for $i from 1 through 11 {
		.col-sm-offset-#{$i} {
			margin-left: $width_value * $i!important;
		}
	}
}

@media (min-width: $md-breakpoint) {

	@for $i from 1 through 12 {
		.col-md-#{$i} {
			flex-basis: $width_value * $i!important;
			max-width: $width_value * $i!important;
		}
	}

	@for $i from 1 through 11 {
		.col-md-offset-#{$i} {
			margin-left: $width_value * $i!important;
		}
	}
}

@media (min-width: $lg-breakpoint) {

	@for $i from 1 through 12 {
		.col-lg-#{$i} {
			flex-basis: $width_value * $i!important;
			max-width: $width_value * $i!important;
		}
	}

	@for $i from 1 through 11 {
		.col-lg-offset-#{$i} {
			margin-left: $width_value * $i!important;
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {
	.container {
		max-width: 960px;
	}
	.container, .container-fluid, .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media(max-width: $md-breakpoint) {
	
}

@media(max-width: $sm-breakpoint) {

}

@media(max-width: $xs-breakpoint) {
	.container, .container-fluid, .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
		padding-left: 10px;
		padding-right: 10px;
	}
}