.articles {
	&__card {
		$card: &;
		&--video {
			position: relative;
			display: flex;
			height: 236px;
			margin-bottom: 30px;
			background-repeat: no-repeat;
			background-position: center;
			&::before {
				content: '';
				position: absolute;
				top: 0; left: 0;
				width: 100%; height: 100%;
				background-color: rgba(#000, .6);
			}
			#{ $card } {
				&_content {
					position: relative;
					justify-content: center; align-items: flex-end;
					padding-bottom: 50px;
					display: flex; 
					width: 100%;
					z-index: 1;
				}
				&_button {
					position: absolute;
					top: 0; bottom: 0; right: 0; left: 0;
					padding: 0;
					margin: auto;
					display: flex;
					align-items: center; justify-content: center;
					width: 63px; height: 63px;
					border-radius: 32px;
					background-image: url('./../images/icon_play.svg');
					background-color: $dusty-orange;
					background-repeat: no-repeat;
					background-position: 20px;
					background-size: 29px 33px;
					border: none;
					transition: background-color 200ms;
					cursor: pointer;
					&:hover {
						background-color: #d14908;
					}
				}
				&_name {
					font: {
						weight: 700;
						size: 18px;
					}
					color: $white;
					letter-spacing: 0.3px;
					text-align: center;
				}
			}
		}
		
	}
}

@media(max-width: $lg-breakpoint - 1) {

}

@media(max-width: $md-breakpoint) {
	
}

@media(max-width: $sm-breakpoint) {
	.articles {
		&__card {
			$card: &;
			&--video {
				#{ $card } {
					&_content {
						padding-bottom: 30px;
					}
					&_name {
						font-size: 16px;
						padding: 0 20px;
					}
				}
			}
		}
	}
}

@media(max-width: $xs-breakpoint) {

}