.products {
	&__card {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 353px;
		padding: 20px;
		margin-bottom: 20px;
		overflow: hidden;
		transition: transform 200ms, box-shadow 200ms;
		background-color: $white;
		&:hover {
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
			transform: scale(1.02);
		}
		&_badge {
			position: absolute;
			top: 20px; left: 20px;
			display: flex;
			align-items: center; justify-content: center;
			width: 40px; height: 40px;
			border-radius: 20px;
			font: {
				weight: 700;
				size: 12px;
			}
			color: $white;
			text-transform: uppercase;
			letter-spacing: 0.2px;
			&--new {
				background-color: $greyish-brown;
			}
			&--sail {
				background-color: $dusty-orange;
				background-image: url('./../images/icon_sail.svg');
				background-repeat: no-repeat;
				background-size: 13px;
				background-position: center;
			}
		}
		&_infoblock {
			display: flex;
			flex-direction: column;
		}
		&_thumbnail {
			height: 150px;
			background-repeat: no-repeat;
			background-position: center;
			margin-bottom: 5px;
		}
		&_type {
			font-size: 12px;
			line-height: 0.2px;
			color: $darkgrey;
			margin-bottom: 10px;
		}
		&_name {
			letter-spacing: 0.2px;
			color: $black;
			margin-bottom: 5px;
		}
		&_brand {
			font-size: 12px;
			letter-spacing: 0.2px;
  			color: $dusty-orange;
			text-decoration: underline;
		}
		&_price {
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			color: $black;
			letter-spacing: 0.2px;
			&--current {
				font-weight: 700;
			}
			&--oldest {
				margin-left: 7px;
			}
		}
		&_status {
			position: relative;
			padding-left: 17px;
			margin-top: 5px;
			font-size: 12px;
			letter-spacing: 0.2px;
			color: $darkgrey;
			&::before {
				content: '';
				position: absolute;
				top: 0; bottom: 0; left: 0;
				margin: auto;
				width: 9px; height: 9px;
				border-radius: 4.5px;
			}
			&--in_stock {
				&::before {
					background-color: #0e9f06;
				}
			}
			&--to_order {
				&::before {
					background-color: #f8b800;
				}
			}
			&--not_available {
				&::before {
					background-color: tomato;
				}
			}
		}
	}
}