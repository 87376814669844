.modal {
	position: fixed;
	top: 0; left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%; height: 100%;
	background-color: rgba($camo-green, .6);
	opacity: 0;
	visibility: hidden;
	transition: opacity 200ms;
	z-index: 30;
	&.open {
		display: flex;
		visibility: visible;
		opacity: 1;
	}
	&__form {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 30px;
		max-width: 649px; width: 100%;
		background-color: $white;
		&_closer {
			position: absolute;
			top: 30px; right: 30px;
			background-color: transparent;
			border: none;
			font-size: 12px;
			letter-spacing: 0.2px;
			color: $greyish;
			cursor: pointer;
		}
		&_wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			&:not(:last-child) { margin-bottom: 30px; }
		}
		&_title {
			@include Bodycopy-B4-dark;
		}
		&_group {
			width: 50%;
			&:not(:first-child) {
				margin-left: 30px;
			}
		}
		&_label {
			display: block;
			margin-bottom: 10px;
			font-size: 14px;
			color: $greyish;
		}
		&_agreement {
			display: flex;
			align-items: center;
			span {
				a {
					margin-left: 3px;
				}
			}
		}
		&_input {
			width: 100%;
			height: 34px;
			border: solid 1px #a5a5a5;
			padding: 0 13px;
		}
		&_submit {
			max-width: 179px;
			background-color: $dusty-orange;
			transition: background-color 200ms;
			border: none;
			&:hover {
				background-color: #d14908;
			}
			&.disabled {
				color: #fff;
				background-color: #999;
				&:hover {
					color: #fff;
					background-color: #999;
				}
			}
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {

}

@media(max-width: $md-breakpoint) {
	.modal {
		&__form {
			max-width: 297px;
			padding: 30px 20px;
			&_title {
				display: block;
				max-width: 196px;
			}
			&_closer {
				right: 20px;
			}
			&_wrapper {
				flex-direction: column;
			}
			&_group {
				width: 100%;
				&:not(:first-child) { margin-left: 0; margin-top: 20px; }
			}
			&_submit {
				align-self: flex-end;
			}
		}
	}
}

@media(max-width: $sm-breakpoint) {

}

@media(max-width: $xs-breakpoint) {
	.modal {
		&__form {
			&_title {
				font-size: 13px;
			}
		}
	}
}