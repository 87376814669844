.products__filters {
	$filters: &;
	background-color: #fff;
	overflow: hidden;
	&.mobile {
		position: absolute;
		width: 100%; height: 100%;
		z-index: 1;
		transform: translateX(-100%);
		transition: transform 300ms;
		padding-bottom: 105px;
		&.active {
			overflow: auto;
			transform: translateX(0)
		}
		#{ $filters } {
			&_group {
				&.range {
					.irs {
						max-width: 300px;
					}
				}
			}
		}
	}
	&_closer {
		display: none;
		position: absolute;
		top: 20px; right: 15px;
		background-color: transparent;
		border: none;
		font-size: 12px;
		letter-spacing: 0.2px;
		color: $greyish;
		cursor: pointer;
	}
	&_group {
		border-bottom: 1px solid $white-two;
		padding: 20px 15px;
		&.checkboxes {
			padding: 20px 5px;
			#{ $filters }_title {
				display: block;
				padding: 0 5px;
				margin-bottom: 10px;
			}
		}
		&.range {
			padding-bottom: 55px;
		}
	}
	&_title {
		@include Bodycopy-B3-dark;
	}

	&_checkboxes {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	&_checkbox {
		display: flex;
		background-color: transparent;
		margin: 5px;
		cursor: pointer;
		transition: background-color 200ms;
		&:hover {
			span {
				color: $dusty-orange;
			}
		}
		&.current {
			span {
				color: #fff;
				background-color: $dusty-orange;
				border-color: $dusty-orange;
			}
		}
		span {
			display: block;
			padding: 5px 10px;
			font-size: 14px;
			border: 1px solid $greyish;
			transition: color 200ms;
		}
	}
	&_other {
		display: flex;
		align-items: center;
		margin: 5px;
		text-decoration: underline;
		@include Bodycopy-links-orange;
	}
	&_results {
		letter-spacing: 0.2px;
		color: $greyish;
	}
	&_quantity {
		margin-left: 5px;
		font-weight: 400;
	}
	&_actions {
		margin-top: 20px;
	}
	&_button {
		font: {
			weight: 700;
			size: 14px;
		}
		padding: 10px 20px;
		text-transform: uppercase;
		letter-spacing: 0.2px;
		background-color: transparent;
		border: 1px solid $darkgrey;
		cursor: pointer;
	}
	&_submit {
		color: $white;
		background-color: $dusty-orange;
		border-color: $dusty-orange;
		transition: background-color 200ms;
		&:hover {
			background-color: #d14908;
		}
	}
	&_clear {
		color: $black;
		margin-left: 20px;
		transition: color 200ms ease;
		&:hover {
			color: $dusty-orange;
		}
	}
	&_params {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;
		&:not(:last-child) { margin-bottom: 30px; }
		&.current {
			&::before {
				background-color: $dusty-orange;
				border-color: $dusty-orange;
			}
		}
		&::before {
			content: '';
			position: absolute;
			top: 0; bottom: 0; left: 0;
			margin: auto;
			width: 30px; height: 30px;
			border: solid 1px $greyish;
			transition: background-color 200ms, border-color 200ms;
		}
		span {
			padding-left: 40px;
			@include Bodycopy-B4-dark;
		}
	}
	.irs-slider {
		width: 14px;
		height: 28px;
		background: $dusty-orange;
		background-image: none;
		border-radius: 0;
		border: none;
	}
	.irs-line {
		border-radius: 0;
		background: $white-two;
		background-image: none;
	}
	.irs-bar {
		border-color: $greyish;
		background: #fff;
		background-image: none;
	}
	.irs-from, .irs-to {
		top: 65px;
		border-radius: 0;
		padding: 0;
		background: transparent;
		@include Bodycopy-B4-dark;
	}
	.irs-to {
		left: auto!important;
		right: 0;
	}
	.irs-from {
		left: 0!important;
	}
	.irs-max, .irs-min {
		display: none;
		top: 65px;
		visibility: visible!important;
		border-radius: 0;
		padding: 0;
		background: transparent;
		@include Bodycopy-B4-dark;
	}
}

@media(max-width: $lg-breakpoint - 1) {

}

@media(max-width: $md-breakpoint) {
	
}

@media(max-width: $sm-breakpoint) {
	.products__filters {
		$filters: &;
		&.mobile {
			#{ $filters } {
				&_group {
					&.range {
						.irs {
							max-width: 100%;
						}
					}
				}
			}
		}
		&_closer {
			display: block;
		}
	}
}

@media(max-width: $xs-breakpoint) {

}