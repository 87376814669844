.main {
	&__banners {
		&_slider {
			height: 436px;
			background-color:  #fecd0f;
		}
	}
	&__slider {
		.slick-list, .slick-track { height: 100%; }
		.slick-slide > div { height: 100%; }
		.slick-dots {
			position: absolute;
			bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			li {
				width: 12px; height: 12px;
				&:not(:first-child) { margin-left: 10px; }
				button {
					width: 12px; height: 12px;
					border-radius: 50%;
					border: 1px solid #393939;
					background-color: #fff;
					transition: background-color 200ms;
					padding: 0;
					cursor: pointer;
				}
				&.slick-active {
					button {
						background-color: #393939;
					}
				}
			}
		}
		&_slide {
			display: flex!important;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%; height: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			&__content {
				text-align: center;
				p {
					font-size: 18px;
					letter-spacing: 0.2px;
					color: #fff;
				}
				b {
					display: block;
					margin-bottom: 10px;
					font: {
						weight: 700;
						size: 48px;
					}
					color: #fff;
					line-height: 1;
					letter-spacing: 0.6px;
				}
			}
			&__link {
				margin-top: 40px;
				max-width: 130px;
				background-color: $dusty-orange;
				transition: background-color 200ms;
				&:hover {
					background-color: #d14908;
				}
			}
		}
		.slick {
			&-arrow {
				top: 46%;
				width: 28px; height: 28px;
				border-radius: 14px;
				border: none;
				padding: 0;
				background-color: $white;
				z-index: 1;
				transition: box-shadow 200ms;
				svg {
					fill: #888888;
				}
				&:hover {
					box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
					svg {
						fill: #e5692d;
					}
				}
			}
			&-prev {
				left: 20px;
			}
			&-next {
				right: 20px;
			}
		}
	}
}