.ordering {
	padding-top: 40px;
	&__form {
		&_userinfo {
			background-color: #fff;
			padding: 30px 25px 10px;
			&__title {
				display: block;
				@include Bodycopy-B4-dark;
				margin-bottom: 32px;
			}
		}
		&_inputs {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
		&_group {
			margin-bottom: 20px;
			max-width: 230px;
			width: 100%;
			&:nth-child(2n) {
				margin-left: 18px;
			}
		}
		&_label {
			display: block;
			margin-bottom: 10px;
			font-size: 14px;
			color: $darkgrey;
		}
		&_input {
			width: 100%;
			height: 34px;
			border: solid 1px $greyish;
			padding: 0 13px;
			font-size: 14px;
		}
		&_submit {
			max-width: 179px;
			background-color: $dusty-orange;
			transition: background-color 200ms;
			border: none;
			cursor: pointer;
			&:hover {
				background-color: #d14908;
			}
		}

		&_productinfo {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			background-color: #fff;
			padding: 30px;
			padding-top: 10px;
		}
		&_products {
			&__group {
				display: flex;
				flex-direction: column;
			}
		}
		&_product {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			border-bottom: 1px solid $pinkish-grey;
			padding: 20px 0;
			&__quantity {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
			}
			&__result {
				@include -Headline-H2-black;
			}
			&__articul {
				font-size: 12px;
				letter-spacing: 0.2px;
				color: $darkgrey;
			}
			&__name {
				margin-top: 10px;
				@include Bodycopy-B4-dark;
			}
		}
		&_results {
			margin-top: 26px;
			&__sum {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: flex-end;
			}
			&__text {
				@include -Headline-H2-black;
			}
			&__price {
				margin-left: 10px;
				font: {
					weight: 700;
					size: 32px;
				}
				letter-spacing: 0.4px;
				line-height: 32px;
				color: $black;
			}
			&__send {
				margin-top: 20px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-end;
			}
			&__agreement {
				position: relative;
				padding-left: 40px;
				cursor: pointer;
				&.current {
					&::before {
						background-color: $dusty-orange;
						border-color: $dusty-orange;
					}
				}
				&::before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					margin: auto;
					width: 30px;
					height: 30px;
					border: solid 1px #a5a5a5;
					-webkit-transition: background-color 200ms, border-color 200ms;
					transition: background-color 200ms, border-color 200ms;
				}
				span {
					font-size: 14px;
					a {
						font-size: 14px;
						color: $dusty-orange;
						transition: color 200ms;
						&:hover {
							color: $black;
						}
					}
				}
			}
		}
	}
	&__link {
		max-width: 219px;
		background-color: $dusty-orange;
		transition: background-color 200ms;
		&:hover {
			background-color: #d14908;
		}
	}
}

@media(max-width: $lg-breakpoint - 1) {
	.ordering {
		&__form {
			&_group {
				max-width: 100%;
				&:nth-child(2n) {
					margin-left: 0;
				}
			}
		}
	}
}

@media(max-width: $md-breakpoint) {
	.ordering {
		&__form {
			&_product {
				&__quantity, &__price, &__factor {
					font-size: 14px;
				}
				&__result {
					font-size: 18px;
				}
			}
			&_results {
				&__text {
					font-size: 18px;
				}
				&__price {
					font-size: 21px;
					line-height: normal;
				}
				&__send {
					margin-top: 40px;
					flex-direction: column;
				}
				&__agreement {
					margin-bottom: 20px;
				}
			}
		}
	}
}

@media(max-width: $sm-breakpoint) {

}

@media(max-width: $xs-breakpoint) {

}