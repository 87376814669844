// Fonts
$general-font: 'OpenSans';
// Colors
$general-color-breadcrumbs: #898989;

$general-color: #0d6874;
$general-color-blue: #4997d3;
$general-color-text: #555555;
$general-color-dark: #829b9f;

$dull-orange: #e08745;
$dusty-orange: #e5692d;
$pinkish-grey: #cfcfcf;
$white-two: #e9e9e9;
$camo-green: #403821;
$white: #ffffff;
$greyish-brown: #615638;
$greyish: #a5a5a5;
$darkgrey: #888888;
$black: #393939;

$color: #333;
$color-black: #000;
$border-color: #eaeaea;
// Slider color
$bg-preloader-color: #fff;
$main-preloader-color: $general-color;
// grids
$container-max-width: 1322px;
$indent_value: 11px;
// breakpoints
$width_value: 8.333333333333334%;
$lg-breakpoint: $container-max-width;
$md-breakpoint: 1024px;
$sm-breakpoint: 767px;
$xs-breakpoint: 540px;
// paddings
$padding-left: $indent_value;
$padding-right: $indent_value;
// margings
$margin-left: -$indent_value;
$margin-right: -$indent_value;
// indets
$indent: 20px;
$md-indent: $indent / 2;
$sm-indent: $md-indent / 2;



@mixin -Headline-H1-dark {
  font-family: OpenSans;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-align: left;
  color: $black;
}

@mixin -Headline-H1-white {
  font-family: OpenSans;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-align: left;
  color: $white;
}

@mixin -Headline-H1-white {
  font-family: OpenSans;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: $white;
}

@mixin -Headline-H1-white {
  font-family: OpenSans;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: $white;
}

@mixin -Headline-H1-dark {
  font-family: OpenSans;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: $black;
}

@mixin -Headline-H3-black {
  font-family: OpenSans;
  font-size: 32px;
  letter-spacing: 0.4px;
  text-align: left;
  color: $black;
}

@mixin -Headline-H1-white {
  font-family: OpenSans;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-align: left;
  color: $white;
}

@mixin -Headline-H2-white {
  font-family: OpenSans;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: center;
  color: $white;
}

@mixin -Headline-H2-black {
  font-family: OpenSans;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: left;
  color: $black;
}

@mixin -Headline-H3-black {
  font-family: OpenSans;
  font-size: 21px;
  letter-spacing: 0.3px;
  text-align: left;
  color: $black;
}

@mixin -Headline-H2-white {
  font-family: OpenSans;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2px;
  text-align: center;
  color: $white;
}

@mixin Bodycopy-B2-white {
  font-family: OpenSans;
  font-size: 18px;
  letter-spacing: 0.2px;
  text-align: left;
  color: $white;
}

@mixin Bodycopy-B2-dark {
  font-family: OpenSans;
  font-size: 18px;
  letter-spacing: 0.2px;
  text-align: left;
  color: $black;
}

@mixin Bodycopy-B3-white {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.2px;
  text-align: left;
  color: $white;
}

@mixin -Bodycopy-B2-white {
  font-family: OpenSans;
  font-size: 16px;
  letter-spacing: 0.2px;
  text-align: left;
  color: $white;
}

@mixin Bodycopy-B3-dark {
  font-family: OpenSans;
  font-size: 16px;
  letter-spacing: 0.2px;
  text-align: left;
  color: $black;
}

@mixin uttons-DARK {
  font-family: OpenSans;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: $black;
}

@mixin uttons-WHITE {
  font-family: OpenSans;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.2px;
  text-align: center;
  color: $white;
}

@mixin Bodycopy-B4-white {
  font-family: OpenSans;
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: $white;
}

@mixin Bodycopy-B4-dark {
  font-family: OpenSans;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: $black;
}

@mixin Bodycopy-B5-white {
  font-family: OpenSans;
  font-size: 13px;
  line-height: 1.54;
  text-align: left;
  color: $white;
}

@mixin Bodycopy-links-orange {
  font-family: OpenSans;
  font-size: 12px;
  letter-spacing: 0.2px;
  text-align: left;
  color: $dusty-orange;
}

@mixin Bodycopy-links-dark {
  font-family: OpenSans;
  font-size: 12px;
  letter-spacing: 0.2px;
  text-align: left;
  color: $darkgrey;
}

@mixin Bodycopy-links-white {
  font-family: OpenSans;
  font-size: 12px;
  letter-spacing: 0.2px;
  text-align: left;
  color: $white;
}
